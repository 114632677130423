<template>
  <v-container fluid class="pa-0 mt-0">
    <v-card width="1300" class="mx-auto transparent" tile elevation="0">
      <v-expansion-panels v-model="openCard" multiple :readonly="readonly">
        <v-expansion-panel v-for="(month, index) in months" :key="index">
          <v-expansion-panel-header
            class="title mx-auto"
            @click="!month.transactions && getTransactionsByMonth(month.id)"
          >
            {{ month.label }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="showLoading">
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </div>

            <div v-else-if="month.transactions">
              <v-row>
                <v-col v-if="!isResellerTransaction" class="my-auto">
                  <download-csv
                    :data="exportTransactionData(month.transactions)"
                    :month="month.label"
                    :primaryName="company.name"
                    :labels="labels"
                    :fields="clientFields"
                  >
                    <v-btn
                      text
                      class="text-none text-body-2 font-weight-bold"
                      color="accent"
                    >
                      <svg
                        style="width: 24px; height: 24px"
                        viewBox="0 0 24 24"
                        class="mr-2"
                      >
                        <path
                          fill="currentColor"
                          d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
                        />
                      </svg>
                      Fazer o download do relatório
                    </v-btn>
                  </download-csv>
                </v-col>
                <v-col v-if="isResellerTransaction">
                  <download-csv
                    :data="exportTransactionData(month.transactions)"
                    :month="month.label"
                    :primaryName="company.name"
                    :labels="labels"
                    :fields="fields"
                    class="pa-0 ma-0"
                  >
                    <v-btn
                      text
                      class="text-none text-body-2 font-weight-bold mr-2 my-2"
                      color="accent"
                    >
                      <svg
                        style="width: 24px; height: 24px"
                        viewBox="0 0 24 24"
                        class="mr-2"
                      >
                        <path
                          fill="currentColor"
                          d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
                        />
                      </svg>
                      Relatório completo
                    </v-btn>
                  </download-csv>
                  <download-csv
                    :data="exportSuperLogica(month.transactions)"
                    :month="month.label"
                    :primaryName="company.name"
                    :fields="superLogicaFileds"
                    v-if="exportSuperLogica(month.transactions)"
                  >
                    <v-btn
                      text
                      class="text-none text-body-2 font-weight-bold mr-2 my-2"
                      color="accent"
                    >
                      <svg
                        style="width: 24px; height: 24px"
                        viewBox="0 0 24 24"
                        class="mr-2"
                      >
                        <path
                          fill="currentColor"
                          d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
                        />
                      </svg>
                      SuperLogica
                    </v-btn>
                  </download-csv>
                  <v-btn
                    v-else
                    text
                    @click="showSnackBar = true"
                    class="text-none text-body-2 font-weight-bold my-2"
                    color="accent"
                  >
                    <svg
                      style="width: 24px; height: 24px"
                      viewBox="0 0 24 24"
                      class="mr-2"
                    >
                      <path
                        fill="currentColor"
                        d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
                      />
                    </svg>
                    SuperLogica
                  </v-btn>
                </v-col>

                <v-col>
                  <p
                    v-if="month.totalValue"
                    class="float-right d-flex align-center my-auto"
                  >
                    <span class="mr-4">
                      <span class="font-weight-bold">
                        Saldo final
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="mr-1 mb-1"
                              color="accent"
                              v-bind="attrs"
                              v-on="on"
                              v-text="'ph-question'"
                              size="18"
                            />
                          </template>
                          <span
                            >Transações de empresas não faturáveis não
                            contabilizarão para o saldo final do
                            faturamento</span
                          >
                        </v-tooltip>
                      </span>
                      <div>{{ formatMoney(month.totalValue) }}</div>
                    </span>
                    <v-tooltip bottom v-if="isConecta && isResellerTransaction">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey"
                          class="ml-2"
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            showBillableCompanies = !showBillableCompanies
                          "
                        >
                          <v-icon
                            v-text="
                              showBillableCompanies
                                ? 'ph-fill ph-eye-slash'
                                : 'ph-fill ph-eye'
                            "
                          />
                        </v-btn>
                      </template>
                      <span>{{
                        showBillableCompanies
                          ? "Ocultar transações de empresas não faturáveis"
                          : "Mostrar transações de empresas não faturáveis"
                      }}</span>
                    </v-tooltip>
                  </p>
                </v-col>
              </v-row>
              <v-data-table
                class="mx-0 my-4"
                :headers="dynamicHeaders"
                :items="allTransactions(month)"
                :loading="showLoading"
                mobile-breakpoint="900"
                disable-sort
                no-data-text="Não há contas configuradas com acesso a este usuário"
              >
                <template
                  style="white-space: nowrap"
                  v-slot:item.date="{ item }"
                >
                  {{ formatPeriod(item.init_date, item.end_date) }}
                </template>
                <template v-slot:item.title="{ item }">
                  <p class="header-text-wrap my-0">
                    {{ item.title }}
                  </p>
                </template>
                <template v-slot:item.subscription_type="{ item }">
                  <v-chip x-small>{{
                    getPlanSubscriptionType(item.subscription_type)
                  }}</v-chip>
                </template>
                <template
                  v-if="adminOptions"
                  v-slot:item.canceled_status="{ item }"
                >
                  <span class="d-flex align-center">
                    <v-icon
                      small
                      class="mr-2 mb-1"
                      :color="item.canceled ? 'red' : 'green'"
                    >
                      {{ item.canceled ? "ph-x-circle" : "ph-check-circle" }}
                    </v-icon>
                    {{ item.canceled ? "Cancelada" : "Ativa" }}
                  </span>
                </template>
                <template
                  v-if="isResellerTransaction"
                  v-slot:item.partner_company="{ item }"
                >
                  {{
                    item.reseller_company
                      ? `${item.reseller_company.domain} - ${
                          translateResellerTypes[item.reseller_company.type]
                        }`
                      : ""
                  }}
                </template>
                <template v-slot:item.commission="{ item }">
                  <p
                    style="white-space: nowrap"
                    :class="`my-0
                    ${item.partner_commission < 0 ? 'red--text' : ''} ${
                      !item.partner_commissio ? 'text-center' : ''
                    }`"
                  >
                    {{
                      item.partner_commission || item.partner_commission == 0
                        ? `${formatMoney(item.partner_commission)}`
                        : "--"
                    }}
                  </p>
                </template>
                <template v-slot:item.disccount="{ item }">
                  {{ getDiscountPercentageValue(item, plans, services) }}
                </template>
                <template v-slot:item.installments="{ item }">
                  {{
                    item.installments > 1
                      ? showInstallmentsValues(item)
                      : "À vista"
                  }}
                </template>
                <template v-slot:item.total_price="{ item }">
                  <p
                    style="white-space: nowrap"
                    v-if="item.total_price || item.total_price == 0"
                    :class="`my-0
                    ${item.total_price < 0 ? 'red--text' : ''} ${
                      !item.total_price ? 'text-center' : ''
                    }`"
                  >
                    {{ formatMoney(item.total_price) }}
                  </p>
                  <p class="mt-3 green--text" v-else>Transação aberta</p>
                </template>
              </v-data-table>
            </div>
            <div v-else class="text-center">
              Não há transações para este período.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <Snackbar
      :show="showSnackbar"
      :message="snackbarText"
      :color="snackbarType"
      @close="showSnackbar = false"
    />
  </v-container>
</template>

<script>
import JsonCSV from "@/components/main/shared/download.vue";
import { getDiscountPercentageValue } from "@/helpers/services/billing";
import {
  dotsToComma,
  formatDate,
  formatMoney,
  formatPeriod,
} from "@/helpers/services/utils";
import {
  partnerType,
  STATUS,
  SUBSCRIPTIONS,
} from "@/helpers/variables/backendConstants";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import {
  planPeriodic,
  planPeriodicDescription,
  translateResellerTypes,
} from "@/helpers/variables/translateString";
import { mapGetters } from "vuex";

export default {
  name: "TransactionTable",
  props: {
    months: { type: Array },
    openPanel: { type: Array },
    isResellerTransaction: { type: Boolean, default: false },
  },

  components: { "download-csv": JsonCSV },

  data() {
    return {
      planPeriodicDescription,
      SUBSCRIPTIONS,
      errorMessages,
      successMessages,
      STATUS,
      partnerType,
      clientFields: [
        "domain_company",
        "title",
        "init_date",
        "end_date",
        "licenses",
        "total_price",
        "subscription_type",
        "reseller_name",
      ],
      fields: [
        "domain_company",
        "title",
        "init_date",
        "end_date",
        "licenses",
        "price_by_licenses",
        "plan_price",
        "total_price",
        "subscription_type",
        "reseller_name",
        "reseller_domain",
        "reseller_type",
        "reseller_discount",
        "reseller_commission",
        "total_commission",
        "installments",
        "is_billable",
        "canceled",
      ],
      labels: {
        domain_company: "Domínio",
        title: "Título",
        init_date: "Data de início",
        end_date: "Data de término",
        licenses: "Quantidade",
        price_by_licenses: "Preço por Licença",
        plan_price: "Preço do Plano",
        total_price: "Preço Total",
        subscription_type: "Periodicidade",
        reseller_name: "Nome do Parceiro",
        reseller_domain: "Domínio do Parceiro",
        reseller_type: "Tipo de Parceria",
        reseller_discount: "Desconto do Parceiro (%)",
        reseller_commission: "Comissão do Parceiro (%)",
        total_commission: "Comissão do Parceiro (R$)",
        installments: "Número de Parcelas",
        is_billable: "Faturável",
        canceled: "Cancelado",
      },
      superLogicaFileds: [
        "cliente",
        "se_repete",
        "produto_ou_servico",
        "valor",
        "quantidade",
        "complemento",
        "inicio",
        "fim",
      ],
      openCard: "",
      showSnackbar: false,
      snackbarType: "success",
      snackbarText: "",
      showLoading: false,
      readonly: false,
      planPeriodic,
      translateResellerTypes,
      showBillableCompanies: true,
    };
  },

  beforeMount() {
    this.openCard = this.openPanel;
  },

  watch: {
    openPanel() {
      this.openCard = this.openPanel;
    },
  },
  computed: {
    ...mapGetters(["token", "company", "isConecta", "plans", "services"]),

    adminOptions() {
      return (
        this.isResellerTransaction && this.clientPageView && this.isConecta
      );
    },

    clientPageView() {
      return this.$route.name === "ResellerClientInfo";
    },

    dynamicHeaders() {
      const baseHeaders = [
        {
          text: "Data",
          width: 160,
          value: "date",
        },
        { text: "Nome", width: 180, value: "title" },
        { text: "Tipo", width: 30, value: "subscription_type" },
        {
          text: "Domínio",
          width: 100,
          value: "domain_company",
        },
        {
          text: "Licenças",
          width: 30,
          align: "right",
          value: "licenses",
        },
        {
          text: "Parcelas",
          width: 30,
          align: "right",
          value: "installments",
        },
        {
          text: "Valor Total",
          width: 50,
          align: "right",
          value: "total_price",
        },
      ];
      if (this.adminOptions) {
        baseHeaders.splice(4, 0, {
          text: "Status da transação",
          width: 30,
          value: "canceled_status",
        });
      }
      if (this.isResellerTransaction) {
        baseHeaders.splice(4, 0, {
          text: "Parceiro atual",
          width: 50,
          value: "partner_company",
        });
      }
      if (this.isConecta || this.isResellerTransaction) {
        baseHeaders.splice(4, 0, {
          text: "Comissão",
          width: 50,
          align: "right",
          value: "commission",
        });
      }
      if (this.isConecta || this.isResellerTransaction) {
        baseHeaders.splice(5, 0, {
          text: "Desconto",
          width: 30,
          align: "right",
          value: "disccount",
        });
      }
      return baseHeaders;
    },
  },

  methods: {
    getDiscountPercentageValue,

    formatMoney,

    formatPeriod,

    formatDate,

    dotsToComma,

    getEvents(events) {
      return this.showBillableCompanies
        ? events.filter((transaction) => !transaction.canceled)
        : events.filter(
            (transaction) => transaction.is_billable && !transaction.canceled
          );
    },

    getPlanSubscriptionType(transaction) {
      const subscriptionsType = {
        ANNUAL: "Anual",
        FLEX: "Mensal",
        TRIAL: "Trial",
        ONCE: "Único",
        QUARTELY: "Trimestral",
        SEMESTER: "Semestral",
        BIENNIUM: "Bimestral",
        EXTRA: "Extra",
      };
      let type = subscriptionsType[transaction];
      return type;
    },

    totalValue(transactionsList) {
      const total = transactionsList.reduce((acc, transaction) => {
        if (transaction.total_price && !transaction.canceled) {
          acc += transaction.total_price;
        }
        return acc;
      }, 0);
      return total;
    },

    showInstallmentsValues(transaction) {
      return `${transaction.installments}x de ${formatMoney(
        transaction.total_price / transaction.installments
      )}`;
    },

    getTransactionsByMonth(id) {
      if (this.months[id].transactions === false) {
        return;
      }
      this.showLoading = true;

      const url = this.isResellerTransaction
        ? `${process.env.VUE_APP_API_BASE_URL}/reseller/financial-transaction/list`
        : `${process.env.VUE_APP_API_BASE_URL}/company/financial-transaction/list`;

      this.$axios
        .get(url, {
          params: {
            date: this.months[id].requestDate,
          },
          headers: {
            Authorization: this.token,
          },
        })
        .then(({ data }) => {
          const hasTransactions = data.length;

          if (hasTransactions) {
            this.months[id].transactions = data;
            this.months[id].totalValue = this.totalValue(
              this.months[id].transactions
            );
          } else {
            this.months[id].transactions = false;
          }
        })
        .catch((err) => {
          this.snackbarText = errorMessages.failure_CSV;
          this.showSnackbar = true;
          this.snackbarType = "error";
          this.months[id].transactions = null;
          console.error(err);
        })
        .finally(() => {
          this.showLoading = false;
          this.readonly = false;
        });
    },

    allTransactions(month) {
      return this.getEvents(month.transactions);
    },

    exportSuperLogica(events) {
      let superLogica = [];

      let items = events
        .slice()
        .filter(
          (transaction) =>
            transaction.is_billable &&
            transaction.total_price &&
            !transaction.canceled
        );

      for (let i = 0; i < items.length; i++) {
        if (items[i].end_date) {
          let resellerCompany = items[i].reseller_company;
          let client =
            items[i].id_google ||
            items[i].company_cnpj ||
            items[i].domain_company;
          let complement =
            `${
              items[i].subscription_type !== SUBSCRIPTIONS.EXTRA
                ? items[i].subscription_type == SUBSCRIPTIONS.ONCE
                  ? "Serviço "
                  : "Plano "
                : "Licencas "
            }` +
            `${planPeriodic[items[i].subscription_type]} ` +
            `${items[i].licenses} licencas. ` +
            `Utilizacao: ${formatDate(items[i].init_date)} - ${formatDate(
              items[i].end_date
            )}. ${items[i].domain_company}.`;

          if (
            resellerCompany &&
            resellerCompany.type == this.partnerType.INTERMEDIARY
          ) {
            client = resellerCompany.id_google || resellerCompany.domain;
          }

          let se_repete = items[i].installments > 1 ? 0 : -1;

          let valor = items[i].installments
            ? items[i].total_price / items[i].installments
            : items[i].total_price;

          let valorFormatado = valor.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          let date = new Date();
          let data_inicio = "";
          let data_final = "";
          if (se_repete == 0) {
            data_inicio = date.toLocaleDateString("pt-BR");
            date.setMonth(date.getMonth() + (items[i].installments - 1));
            data_final = date.toLocaleDateString("pt-BR");
          }

          let data = {
            cliente: client,
            se_repete: se_repete,
            produto_ou_servico: `${items[i].title} - ${
              planPeriodic[items[i].subscription_type] === "Extras"
                ? "Anual"
                : planPeriodic[items[i].subscription_type]
            }`,
            valor: valorFormatado,
            quantidade: 1,
            complemento: complement,
            inicio: data_inicio,
            fim: data_final,
          };

          superLogica.push(data);
        }
      }

      return superLogica.length > 0 ? superLogica : null;
    },

    exportTransactionData(events) {
      let transactionData = [];
      for (let i = 0; i < events.length; i++) {
        let resellerCompany = events[i].reseller_company;

        let is_in_installments = events[i].installments
          ? events[i].installments
          : 1;

        let data = {
          domain_company: events[i].domain_company,
          title: events[i].title,
          init_date: formatDate(events[i].init_date),
          end_date: formatDate(events[i].end_date),
          licenses: events[i].licenses,
          price_by_licenses: dotsToComma(events[i].price_by_licenses),
          plan_price: dotsToComma(events[i].plan_price),
          total_price: dotsToComma(events[i].total_price),
          subscription_type: events[i].subscription_type,
          installments: is_in_installments,
          is_billable:
            events[i].is_billable === null
              ? "-"
              : events[i].is_billable
              ? "Sim"
              : "Não",
          canceled: events[i].canceled ? "Cancelado" : "-",
        };
        resellerCompany = true;
        if (resellerCompany) {
          let discount = resellerCompany.discount * 100;
          let commission = resellerCompany.commission * 100;

          let total_commission =
            parseFloat(resellerCompany.commission * events[i].total_price) || 0;

          data["reseller_name"] = resellerCompany.name;
          data["reseller_domain"] = resellerCompany.domain;
          data["reseller_type"] =
            this.translateResellerTypes[resellerCompany.type];
          data["reseller_commission"] = dotsToComma(commission, 0);
          data["reseller_discount"] = dotsToComma(discount, 0);
          data["total_commission"] = dotsToComma(total_commission, 0);
        }

        transactionData.push(data);
      }
      return transactionData.length > 0 ? transactionData : null;
    },
  },
};
</script>
<style>
.header-text-wrap {
  white-space: normal;
  word-break: break-word;
}
</style>
