<template>
  <div>
    <div class="mt-2" v-if="!editingTemplate">
      <h1 class="font-weight-bold mr-4" style="font-size: 22px">
        {{ $t("companyProfile.companyInfo") }} - {{ company.name }}
      </h1>

      <small class="pl-0 pt-0">
        {{ $t("companyProfile.partner") }}: {{ company.reseller_company.name }}
      </small>
    </div>
    <div class="pa-3 mb-3 mt-5">
      <v-form
        ref="formProfile"
        v-model="validProfileFields"
        lazy-validation
        onsubmit="return false;"
        class="mt-4"
      >
        <v-row
          v-for="field in editable_company_data_fields"
          :key="field.data_key"
        >
          <v-col
            v-if="field.data_key === 'phone' && field.show"
            cols="12"
            sm="3"
            align-self="center"
            class="pl-4"
          >
            <v-card-text class="font-weight-bold body-2 pa-0"
              >{{ field.name
              }}<span v-if="field.required" class="red--text"
                >*</span
              ></v-card-text
            >
          </v-col>
          <v-col
            v-else-if="field.show"
            cols="12"
            sm="3"
            align-self="start"
            class="pa-0 pl-4"
            style="margin-top: 6px"
          >
            <v-card-text class="font-weight-bold body-2 pa-0"
              >{{ field.name
              }}<span v-if="field.required" class="red--text"
                >*</span
              ></v-card-text
            >
          </v-col>

          <v-col class="text-left pa-0" align-self="center" cols="12" sm="8">
            <InternationalPhone
              v-if="field.data_key === 'phone' && field.show"
              color="primary"
              ref="phone"
              class="body-2"
              :placeholder="$t('companyProfile.phoneNumber')"
              :hint="$t('companyProfile.phoneHint')"
              :phone="editedData.phone"
              :rules="requiredPhoneNumber"
              @update="updatePhone"
              @keyup.enter="setFocus('site')"
            />

            <div
              v-else-if="field.data_key === 'language' && field.show"
              class="d-flex align-center justify-center"
            >
              <v-autocomplete
                v-model="editedData[field.data_key]"
                :items="languages"
                item-text="lang"
                item-value="langCode"
                @input="emitCompanyProfileChanges"
                class="pa-0 my-0 body-2"
              >
                <template v-slot:prepend-inner="">
                  <span class="icon-width">
                    {{ countryFlags[editedData[field.data_key]] || "🇧🇷" }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <v-text-field
              v-else-if="field.show"
              color="accentSuite"
              v-model="editedData[field.data_key]"
              @input="emitCompanyProfileChanges"
              :disabled="!field.editable"
              :rules="field.rules"
              class="pa-0 my-0 body-2"
              autocomplete="off"
              :placeholder="field.placeholder || ''"
              :hint="field.hint || ''"
            />
          </v-col>
        </v-row>
      </v-form>
      <div class="mt-10 mx-1">
        <div class="mt-1 mb-1 d-flex align-center" style="gap: 10px">
          <v-btn
            elevation="0"
            outlined
            text
            small
            class="text-none font-weight-bold"
            @click="$emit('updateLogoUrl')"
          >
            <v-icon left dark> ph-cloud-arrow-up </v-icon>
            {{ $t("companyProfile.uploadLogo") }}</v-btn
          >
          <v-btn
            :disabled="!logoUrl"
            @click="$emit('removeLogoUrl')"
            small
            icon
            text
            class="pa-0 text-none font-weight-bold text-body-2 red--text"
            ><v-icon>ph-trash</v-icon></v-btn
          >
        </div>
        <div v-if="logoUrl">
          <v-img :src="logoUrl" contain max-width="300"></v-img>
        </div>
        <v-card
          v-else
          @click="$emit('updateLogoUrl')"
          elevation="0"
          class="d-flex align-center justify-center default-image-preview"
          max-width="260"
        >
          <v-icon color="grey" class="py-12" size="50">ph-pencil-simple</v-icon>
        </v-card>
      </div>

      <p class="mb-0 mt-4 grey-darken-1--text text-caption">
        <span class="red--text">*</span> {{ $t("common.requiredField") }}
      </p>
    </div>
  </div>
</template>

<script>
import { removeMask, requiredPhoneNumber } from "@/helpers/services/utils";
import { mapGetters } from "vuex";

export default {
  name: "CompanyProfileInfo",

  props: {
    logoUrl: { type: String, default: "" },
  },

  data: () => ({
    requiredPhoneNumber,
    countryFlags: {
      "pt-br": "🇧🇷",
      en: "🇺🇸",
    },
    validProfileFields: false,
    editedData: {
      responsible_email: "",
      phone: "",
      site: "",
      ddi: 55,
      language: "pt-br",
    },
  }),

  computed: {
    ...mapGetters(["company"]),

    editingTemplate() {
      return this.$route.name === "TemplateEdit";
    },

    languages() {
      return [
        { lang: this.$t("languages.ptBR"), langCode: "pt-br" },
        { lang: this.$t("languages.en"), langCode: "en" },
      ];
    },

    company_data_fields() {
      return {
        company_name: {
          name: this.$t("companyProfile.companyName"),
          data_key: "name",
        },
        company_reseller_name: {
          name: this.$t("companyProfile.partner"),
          data_key: "reseller_name",
        },
      };
    },

    editable_company_data_fields() {
      return {
        responsible_email: {
          name: this.$t("companyProfile.adminEmail"),
          editable: true,
          data_key: "responsible_email",
          required: true,
          rules: [
            (v) => !!v || this.$t("textFieldRules.requiredEmail"),
            (v) =>
              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) ||
              this.$t("textFieldRules.invalidEmail"),
          ],
          show: !this.editingTemplate,
        },
        site: {
          name: this.$t("companyProfile.site"),
          editable: true,
          data_key: "site",
          required: false,
          show: true,
        },
        phone: {
          name: this.$t("companyProfile.mainPhone"),
          editable: true,
          data_key: "phone",
          required: true,
          show: true,
        },
        language: {
          name: this.$t("companyProfile.language"),
          editable: true,
          data_key: "language",
          required: false,
          show: !this.editingTemplate,
        },
        full_address: {
          name: this.$t("companyProfile.completeAddress"),
          editable: true,
          data_key: "full_address",
          required: false,
          placeholder: this.$t("companyProfile.addressExample"),
          hint: this.$t("companyProfile.addressHint"),
          show: true,
        },
      };
    },
  },

  methods: {
    removeMask,

    updatePhone(event) {
      this.editedData.phone = event;
      this.emitCompanyProfileChanges();
    },

    emitCompanyProfileChanges() {
      this.$emit("updateCompanyInfo", {
        ...this.editedData,
        phone: this.editedData.phone
          ? `+${removeMask("phone", this.editedData.phone)}`
          : "",
      });
    },

    resetFields() {
      this.assignCompanyEditableData();
    },

    assignCompanyEditableData() {
      this.editedData = {
        phone: this.company.phone || "",
        ddi: this.company.ddi || 55,
        language: this.company.language || "pt-br",
        responsible_email: this.company.responsible_email,
        site: this.company.site,
        full_address: this.company.full_address,
      };
    },

    setFocus(input) {
      this.$refs[input].focus();
    },
  },

  mounted() {
    this.assignCompanyEditableData();
  },
};
</script>

<style scoped>
.default-image-preview {
  background-color: #dfdfdf;
  transition: 0.2s ease-in;
}

.default-image-preview:hover {
  background-color: #cfcfcf;
}

.icon-width {
  width: 59px;
  font-size: 16px !important;
  margin-top: 4px;
  margin-left: 16px;
}
</style>
