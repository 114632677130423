<template>
  <!-- TODO: DESCONTINUAR ESTA PÁGINA -->
  <v-container
    fluid
    :class="`${
      $vuetify.breakpoint.smAndDown ? 'px-2 overflow-x-hidden' : ''
    } pb-5 overflow-y-auto py-0`"
    style="height: 88vh"
  >
    <v-navigation-drawer
      v-if="showEditorSidebar"
      v-model="showEditorDrawer"
      app
      permanent
      right
      clipped
      class="border-left overflow-y-hidden"
      :width="sidebarWidth"
      :mini-variant.sync="mini"
    >
      <v-toolbar flat>
        <v-app-bar-nav-icon @click="toggleMenu"></v-app-bar-nav-icon>

        <v-toolbar-title class="pl-0 mb-1 mx-auto">
          <v-tabs
            v-model="selectedTab"
            background-color="transparent"
            color="primary"
            icons-and-text
            hide-slider
            grow
          >
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :href="`#tab-${tab.value}`"
              :class="`py-2 text-none text-body-2 font-weight-medium  ${
                selectedTab == `tab-${tab.value}`
                  ? 'background primary--text'
                  : 'secondary--text text--lighten-2 grey lighten-3'
              }`"
            >
              {{ tab.title }}
              <v-icon v-text="tab.icon" class="mb-2" />
            </v-tab>
          </v-tabs>
        </v-toolbar-title>
      </v-toolbar>

      <div class="border-bottom" style="height: 3px"></div>

      <v-tabs-items v-model="selectedTab" class="sidebar-container">
        <v-tab-item value="tab-custom" class="sidebar-scroll-content">
          <TemplateEditorDrawer
            :isMiniVariant="mini"
            :emptyHTMLTemplate="isTemplateEmpty"
            @banner="setBanner"
            @footer="setContentInTheEnd"
            @structure="setContentInTheStart"
            @icon="setContentInEditor"
            @add-content="setContentInEditor"
            @add-button="addButton"
          />
        </v-tab-item>
        <v-tab-item value="tab-info" class="sidebar-scroll-content">
          <v-card flat class="px-2 fill-height overflow-y-auto">
            <CompanyProfileForm
              :companyProfile="company"
              @close="editCompanyInfoDialog = false"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-navigation-drawer>

    <v-skeleton-loader v-if="showLoading" type="table-heading" class="mt-4" />

    <!-- INÍCIO DO CABEÇALHO DA PÁGINA DE EDIÇÃO -->
    <v-row v-else class="align-center pl-1 mb-0">
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="7"
        lg="7"
        xl="7"
        class="overflow-x-hidden px-0"
      >
        <v-card-actions class="align-center px-0 pb-0">
          <v-card-text :class="`template-config ma-0 pa-0`">
            <v-card-title class="mr-0 mb-1 py-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <input
                    v-bind="attrs"
                    v-on="on"
                    v-model="templateData.name"
                    :size="templateData.name.length + 5"
                    :class="`${!titleRule ? 'error-input' : ''}`"
                  />
                </template>
                <span>Renomear</span>
              </v-tooltip>
              <v-tooltip bottom class="ml-1" v-if="!isCompanyMicrosoft">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!templateData.id"
                    @click="setOrDeleteNewUserModel()"
                    v-bind="attrs"
                    v-on="on"
                    :class="`my-auto text-none font-weight-medium`"
                    id="v-step-10"
                    icon
                    large
                    :color="setIsNewUserModel ? 'accent' : 'grey darken-1'"
                  >
                    <v-icon v-text="'ph-user-gear'" />
                  </v-btn>
                </template>
                <span>{{
                  setIsNewUserModel
                    ? "Desmarcar modelo para novos usuários"
                    : "Definir modelo para novos usuários"
                }}</span>
              </v-tooltip>
            </v-card-title>
            <span v-if="!titleRule" class="error--text py-0 px-4 caption"
              >O título não pode ultrapassar 50 caracteres.</span
            >

            <v-card-subtitle class="mb-0 pr-0 mr-0 py-0 mt-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <input
                    v-bind="attrs"
                    v-on="on"
                    v-model="templateData.description"
                    placeholder="Clique para inserir uma descrição"
                    :size="
                      templateData.description.length > 40
                        ? templateData.description.length
                        : 35
                    "
                    :class="`${!descriptionRule ? 'error-input' : ''}`"
                  />
                </template>
                <span>Alterar descrição</span>
              </v-tooltip>
            </v-card-subtitle>
            <span v-if="!descriptionRule" class="error--text py-0 px-4 caption"
              >A descrição não pode ultrapassar de 80 caracteres.</span
            >
          </v-card-text>
        </v-card-actions>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5" class="pt-3 mt-0">
        <v-row class="mt-0 py-0 ma-0 align-start">
          <v-col v-if="$vuetify.breakpoint.lgAndUp">
            <v-spacer />
          </v-col>
          <v-col v-if="previewWidth < 1">
            <v-btn
              block
              color="accent"
              class="text-none text-body-1 font-weight-bold pl-4 pr-5 mr-4"
              dark
              outlined
              large
              @click="showPreview = true"
            >
              <v-icon v-text="'ph-eye-slash'" left />
              Visualizar
            </v-btn>
          </v-col>
          <v-col class="mx-0 px-0">
            <div v-if="!canSaveModel">
              <v-btn
                block
                color="accent"
                class="text-none text-body-1 font-weight-bold px-6"
                :dark="canSaveModel"
                large
                :loading="loading"
                :disabled="!canSaveModel"
              >
                <v-icon v-text="'ph-fill ph-floppy-disk-back'" left />
                Salvar
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span
                    style="color: red; font-size: 13px; padding-left: 3px"
                    v-on="on"
                    >Limite de caracteres excedido.</span
                  >
                </template>
                <span
                  >Sua assinatura excedeu o limite máximo de 9.000 caracteres.
                  Reduza {{ htmlExceededSize }} caracteres para
                  prosseguir.</span
                >
              </v-tooltip>
            </div>
            <v-menu
              open-on-hover
              bottom
              left
              offset-y
              class="mx-0"
              v-if="canSaveModel"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  block
                  color="accent"
                  class="text-none text-body-1 font-weight-bold px-6"
                  :dark="canSaveModel"
                  large
                  :loading="loading"
                  :disabled="!canSaveModel"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-text="'ph-fill ph-floppy-disk-back'" left />
                  Salvar
                </v-btn>
              </template>

              <v-list nav width="350" class="font-weight-medium">
                <v-list-item @click="saveTemplateChanges(false, true)">
                  <v-list-item-icon class="mr-4">
                    <v-icon v-text="'ph-check-circle'" color="accent" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Apenas salvar</v-list-item-title>
                    <v-list-item-subtitle
                      class="text-wrap font-weight-regular mt-1 caption"
                    >
                      Salvar as alterações feitas no modelo sem reaplicar para
                      os usuários que o utilizam
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="getTheSaveAction(false)"
                  v-if="!isCompanyMicrosoft"
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon v-text="'ph-clock-clockwise'" color="accent" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Salvar e reaplicar</v-list-item-title>
                    <v-list-item-subtitle
                      class="text-wrap font-weight-regular mt-1 caption"
                    >
                      Salvar as alterações e reaplicar modelo atualizado para os
                      usuários que o utilizam
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <div
          :class="`${
            $vuetify.breakpoint.mdAndUp ? 'mr-2' : ''
          } d-flex flex-column justify-center ${
            $vuetify.breakpoint.xs ? '' : 'align-end'
          }`"
        >
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            :class="`caption grey--text text--darken-1 font-italic my-0 ${
              $vuetify.breakpoint.mobile ? 'mr-4' : 'mr-0'
            } ${$vuetify.breakpoint.xs ? '' : 'float-right'}`"
          >
            Última atualização:
            {{ formatDateAndHour(getUpdatedAtTemplate, true) }}
          </div>
          <div v-if="templateData.id && isConecta">
            <v-switch
              v-if="$vuetify.breakpoint.smAndUp"
              :dense="!$vuetify.breakpoint.xs"
              :disabled="!templateData.id"
              :class="`py-0 my-0 text-subtitle-2 mt-4 'ml-2 pl-2`"
              :value.sync="selectedType"
              @change="getTheSaveAction(true)"
              :true-value="'default'"
              :false-value="'custom'"
              :loading="loading"
              :label="
                isDefaultTemplate
                  ? `Tornar modelo personalizado`
                  : `Tornar modelo padrão`
              "
              color="accent"
              inset
              id="v-step-9"
              hide-details
            />
            <v-btn
              v-else
              color="secondary lighten-3"
              block
              large
              class="text-none text-body-2 font-weight-medium"
              outlined
              @change="getTheSaveAction(true)"
            >
              {{
                isDefaultTemplate
                  ? `Tornar modelo personalizado`
                  : `Tornar este modelo padrão`
              }}
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col>
        <HelpBanner title="Dúvidas sobre a criação de assinatura?" />
      </v-col>
    </v-row>
    <!-- FIM DO CABEÇALHO DA PÁGINA DE EDIÇÃO -->

    <!-- ALERTA PARA DISPOSITIVOS PEQUENOS -->
    <v-alert color="error" id="sizeOfScreenErrorMessage"
      >A tela do seu dispositivo é muito pequena para criar/editar um modelo,
      considere usar um Desktop para essa ação.</v-alert
    >

    <v-card class="pa-0 ma-0 transparent elevation-0">
      <Alert
        v-if="isDefaultTemplate"
        class="mx-3 mt-0 mb-4 py-2 text-body-2"
        :prominent="false"
        color="warning"
      >
        Esse é um template Conecta Nuvem e não pode ser alterado. Você pode
        utilizá-lo ou copiá-lo e editar da maneira como preferir.
      </Alert>

      <div v-if="showLoading" class="text-center py-6">
        <v-progress-circular
          indeterminate
          color="primary"
          size="50"
          width="5"
        ></v-progress-circular>
        <div class="grey--text text--darken-2 mt-2 caption">
          Carregando editor
        </div>
      </div>

      <!-- EDITOR / VISUALIZADOR -->
      <v-row v-else>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : editorWidth">
          <v-card outlined>
            <!-- EDITOR HTML -->
            <v-form class="py-2 px-0 mb-0" style="width: 100%">
              <NewsTip
                position="top"
                name="cn-9539"
                :title="$t('understood.cn_9539.title')"
                :content="$t('understood.cn_9539.content')"
                :start-date="SPRINT_120_2ND_WEEK_START"
                next-tip-name="cn-9543"
                ignore-client-rule
              >
                <Editor
                  :html="templateHtml"
                  :disabled="disableEditor"
                  :height="'45vh'"
                  @editor-ref="editor = $event"
                  @update="updatedHtml = $event"
                  @preview="showPreview = true"
                />
              </NewsTip>

              <span
                :class="`caption ml-2 ${
                  canSaveModel ? 'grey' : 'red'
                }--text text--darken-1 font-italic`"
              >
                {{ htmlSize }}/9000 caracteres
              </span>
            </v-form>
          </v-card>
        </v-col>
        <v-col
          v-if="previewWidth > 0"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : previewWidth"
        >
          <v-card outlined>
            <v-card-actions
              class="align-center text-subtitle-1 text-body-1 font-weight-medium py-3 px-4"
            >
              Pré-visualização
              <!-- TODO: Inserir um autocomplete para trocar as informações para serem visualizadas de acordo com o usuário -->
            </v-card-actions>
            <v-divider />
            <v-card-text class="text-none overflow-auto" v-if="updatedHtml">
              <TemplatePreview
                :class="editorWidth == 6 ? 'template-scale' : ''"
                :html="updatedHtml"
                signature-only
              />
            </v-card-text>
            <v-card-subtitle v-else>
              Nenhuma visualização disponível
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

      <!-- EXCLUIR MODELO -->
      <v-tooltip bottom v-if="isCustom">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!templateData.id || disableButtonWhenSetAsDefault"
            @click="
              (dialogDeleteTemplate = true),
                (template_to_delete = templateData.id)
            "
            v-bind="attrs"
            v-on="on"
            :class="`mt-4 text-none text-body-2 font-weight-medium px-1`"
            id="v-step-10"
            text
            large
            color="grey darken-1"
          >
            <v-icon v-text="'ph-trash'" left />
            Excluir este modelo
          </v-btn>
        </template>
        <span>Excluir modelo</span>
      </v-tooltip>

      <!-- DIALOG: DESATIVAR O MODELO PARA NOVOS USUARIOS -->
      <ConfirmationDialog
        :show="newUserModelDialog"
        action-text="Desmarcar modelo de novos usuários"
        message="Você tem certeza que deseja desmarcar este <b>modelo de assinatura para os novos usuários?</b> Sem ele, será necessário aplicar manualmente a assinatura de todos os novos colaboradores."
        @close="cancelLeaveNewUserDialog()"
        @ok="confirmCloseNewUserDialog()"
      />

      <!-- DIALOG: CONFIRMAR EXCLUSÃO DE UM TEMPLATE -->
      <v-dialog v-model="dialogDeleteTemplate" width="500">
        <v-card>
          <v-card-title primary-title>Confirmar exclusão</v-card-title>
          <v-card-text>Você realmente deseja excluir o template? </v-card-text>
          <v-card-text v-if="setIsNewUserModel"
            ><v-alert text prominent color="warning">
              Essa assinatura de e-mail é usada para todos os novos
              colaboradores. Sem ela será necessário a aplicação manualmente de
              assinatura para todos os novos usuários. Caso queira manter um
              modelo inicial deve-se selecionar um novo modelo.
            </v-alert></v-card-text
          >
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="loading"
              color="secondary"
              text
              @click="dialogDeleteTemplate = false"
              >Cancelar</v-btn
            >
            <v-btn
              :disabled="loading"
              :loading="loading"
              text
              @click="exclude(template_to_delete)"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG PREVIEW -->
      <DialogPreview
        :show="showPreview"
        @close="showPreview = false"
        :html="updatedHtml"
      />

      <!-- ALTERAÇÔES NÂO SALVAS  -->
      <HeaderDialog
        :show="showConfirmChanges"
        title="Você tem alterações não salvas"
        :showCancel="true"
        @close="
          showConfirmChanges = false;
          next(false);
        "
        @action="
          showConfirmChanges = false;
          next(true);
          setActionScheduleDialog({ status: true, type: 'help' });
        "
        actionText="Sair sem salvar"
        closeText="Continuar editando"
        :disabledAction="false"
      >
        <template v-slot:body>
          <p class="mt-3">Deseja sair do editor sem salvar as alterações?</p>
        </template>
      </HeaderDialog>

      <!-- NOVAS ASSINATURAS PARA USUÁRIOS -->
      <NewUsersTemplateSignature
        v-if="templateData.id"
        :show="showNewUsersTemplateDialog"
        :template-id="templateData.id"
        @close="showNewUsersTemplateDialog = false"
      />
    </v-card>

    <!-- REAPPLY SIGNATURE DIALOG -->
    <HeaderDialog
      v-if="templateName"
      id="reapply-model"
      :title="templateName"
      subtitle="Modelo de assinatura"
      color="primary"
      :show="showDialog"
      :loading="loadingApplySignature"
      width="700"
      :disabledAction="
        loadingApplySignature || !currentSelected.length || loading
      "
      actionText="Salvar e aplicar modelo"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      @close="closeDialog"
      @action="reapply"
    >
      <template v-slot:body>
        <p class="mt-3">
          Deseja aplicar o modelo de assinatura atualizado para quais usuários?
        </p>

        <p v-if="impactedUsers.length > 0">
          <small>
            <b>OBS:</b> Os usuários que utilizam este modelo de assinatura foram
            selecionados automaticamente. Ao confirmar, suas assinaturas serão
            atualizadas com as alterações.
          </small>
        </p>
        <UsersPicker
          :selected="currentSelected"
          :templateNewHtml="updatedHtml"
          :loading="loading"
          placeholder="Usuários selecionados"
          filled
          label=""
          multiple
          show-email-settings
          show-missing-tags-count
          search
          @update="currentSelected = $event"
        />
        <v-checkbox v-model="notifyUsers" class="mt-0">
          <template v-slot:label>
            <div class="d-flex align-center">
              Notificar alteração de assinatura para todos os usuários
              <v-tooltip top max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    size="18"
                    class="ml-1"
                    v-text="'ph-question'"
                  />
                </template>
                <span>
                  Usuários restritos receberão o e-mail de solicitação da
                  aplicação de assinatura independente dessa opção ser
                  selecionada ou não.
                </span>
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>

        <Alert :prominent="false" color="warning" dense :width="800">
          {{ $t("applySignature.messageDelayToApply") }}
        </Alert>
      </template>
    </HeaderDialog>
  </v-container>
</template>

<script>
import {
  fluxStringToTemplate,
  fluxTemplateToEditor,
} from "@/helpers/services/tagPreview";
import { formatDateAndHour } from "@/helpers/services/utils";
import { SPRINT_120_2ND_WEEK_START } from "@/helpers/variables/dates";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import {
  editableCompanyTags,
  userEditableTags,
} from "@/helpers/variables/tags.js";
import { mapActions, mapGetters, mapMutations } from "vuex";

import CompanyProfileForm from "@/components/billing/CompanyProfileForm";
import UsersPicker from "@/components/forms/field_pickers/UsersPicker.vue";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import HelpBanner from "@/components/help/HelpBanner";
import TemplateEditorDrawer from "@/components/sign/TemplateEditorDrawer";
import DialogPreview from "@/components/sign/general/DialogPreview.vue";
import Editor from "@/components/sign/general/Editor";
import TemplatePreview from "@/components/sign/general/TemplatePreview";
import NewUsersTemplateSignature from "@/components/sign/new_users_signature/NewUsersTemplateSignature";

export default {
  name: "TemplateEditor",
  components: {
    DialogPreview,
    Editor,
    NewUsersTemplateSignature,
    ConfirmationDialog,
    TemplatePreview,
    TemplateEditorDrawer,
    HelpBanner,
    CompanyProfileForm,
    UsersPicker,
  },

  data() {
    return {
      editableTags: { ...userEditableTags, ...editableCompanyTags },
      disableButtonWhenSetAsDefault: false,
      showNewUsersTemplateDialog: false,
      updatedHtml: "",
      dialogDeleteTemplate: false,
      template_to_delete: null,
      showPreview: false,
      templateData: {},
      showLoading: false,
      disableEditor: false,
      currentSelected: [],
      showDialog: false,
      loadingApplySignature: false,
      showConfirmChanges: false,
      next: () => {},
      saveChanges: false,
      newUserModelDialog: false,
      notifyUsers: false,
      editor: null,
      selectedTab: 1,
      tabs: [
        {
          icon: "mdi-palette-advanced",
          title: "Aparência",
          value: "custom",
        },
        {
          icon: "mdi-badge-account",
          title: "Informações",
          value: "info",
        },
      ],
      showEditorDrawer: false,
      mini: false,
      SPRINT_120_2ND_WEEK_START,
    };
  },

  computed: {
    ...mapGetters([
      "editorLayout",
      "token",
      "company",
      "isConecta",
      "loading",
      "users",
      "getUpdatedAtTemplate",
      "showEditorSidebar",
      "isCompanyMicrosoft",
    ]),

    sidebarWidth() {
      if (this.$vuetify.breakpoint.xl) {
        return 460;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return 400;
      } else {
        return "75%";
      }
    },

    isTemplateEmpty() {
      return !this.updatedHtml ? true : false;
    },

    affectedUsers() {
      return this.currentSelected.reduce((acc, email) => {
        const user = this.users.find((user) => user.email === email);
        if (user) {
          acc.push(user);
        }
        return acc;
      }, []);
    },

    editorWidth() {
      if (this.editorLayout === "side_to_side") {
        return 7;
      }
      return 12;
    },

    previewWidth() {
      if (this.editorLayout === "side_to_side") {
        return 5;
      } else if (this.editorLayout === "normal") {
        return 12;
      } else {
        return 0;
      }
    },

    isDefaultTemplate() {
      return this.templateData.signature_type === "default";
    },

    selectedType() {
      return this.currentSelected
        ? this.currentSelected.signature_type
        : "custom";
    },

    setIsNewUserModel() {
      return this.templateData.id == this.company.new_users_signature;
    },

    hasChanges() {
      return (
        fluxStringToTemplate(this.templateHtml).length !==
        fluxStringToTemplate(this.updatedHtml).length
      );
    },

    templateName() {
      return this.templateData.name;
    },

    templateHtml() {
      return fluxTemplateToEditor(this.templateData?.html);
    },

    htmlSize() {
      return fluxStringToTemplate(this.updatedHtml).length;
    },

    htmlExceededSize() {
      let sizeExceeded = this.htmlSize - 9000;
      return sizeExceeded.toLocaleString("pt-BR");
    },

    isCustom() {
      return this.templateData?.signature_type === "custom";
    },

    canSaveModel() {
      return (
        this.htmlSize <= 9000 &&
        this.isCustom &&
        this.descriptionRule &&
        this.titleRule
      );
    },

    descriptionRule() {
      return this.templateData.description?.length <= 80;
    },

    titleRule() {
      return (
        this.templateData.name?.length <= 50 &&
        this.templateData.name?.length > 0
      );
    },

    impactedUsers() {
      const template_id = this.$route.params.key;
      return this.users.reduce(
        (emails, { applied_signature_key, email }) =>
          template_id === applied_signature_key ? emails.concat(email) : emails,
        []
      );
    },
  },

  watch: {
    "templateData.html"() {
      if (this.templateData.html !== this.updatedHtml) {
        this.updatedHtml = this.templateData.html;
      }
    },
    updatedHtml() {
      if (this.hasChanges && !this.saveChanges) {
        window.addEventListener("beforeunload", this.beforeUnloadHandler);
      } else if (!this.hasChanges || this.saveChanges) {
        window.removeEventListener("beforeunload", this.beforeUnloadHandler);
      }
    },
  },

  methods: {
    ...mapActions([
      "changeModelType",
      "setSignatureToUsers",
      "getUsers",
      "setNewUsersTemplate",
      "excludeSignatureTemplate",
    ]),

    ...mapMutations([
      "setLoading",
      "setSnackBar",
      "setUpdatedAtTemplate",
      "setSelectedSignTemplate",
      "setActionScheduleDialog",
      "setSignEditorSidebar",
    ]),

    formatDateAndHour,

    toggleMenu() {
      this.mini = !this.mini;
    },

    /**
     * Function to add content in the start of current html
     * @param {String} element the new element to add
     */
    setContentInTheStart(element) {
      const currentContent = this.editor.getContent();
      const html = element + currentContent;
      this.editor.setContent(html);
    },

    /**
     * Function to add content in the end of current html
     * @param {String} element the new element to add
     */
    setContentInTheEnd(element) {
      const currentContent = this.editor.getContent();
      const html = currentContent + element;
      this.editor.setContent(html);
    },

    /**
     * Function to add content in the last cursor position
     * @param {String} element the new element to add
     */
    setContentInEditor(element) {
      this.editor.focus();
      this.editor.insertContent(element);
    },

    addButton(button) {
      this.setContentInEditor(button);
    },

    setBanner(banner) {
      this.updatedHtml += banner;
      this.templateData.html = this.updatedHtml;
    },

    beforeUnloadHandler(event) {
      event.preventDefault();
      event.returnValue = "";
    },

    async exclude(template_to_delete) {
      await this.excludeSignatureTemplate(template_to_delete).then(() => {
        this.redirectToAllTemplates();
      });
      this.dialogDeleteTemplate = false;
    },

    setOrDeleteNewUserModel() {
      if (this.templateData.id == this.company.new_users_signature) {
        this.newUserModelDialog = true;
      } else {
        this.showNewUsersTemplateDialog = true;
      }
    },

    cancelLeaveNewUserDialog() {
      this.newUserModelDialog = false;
    },

    confirmCloseNewUserDialog() {
      if (this.newUserModelDialog) {
        this.newUserModelDialog = false;
        this.setNewUsersTemplate(null);
      }
    },

    async getTheSaveAction(changeTemplateType = false) {
      if (changeTemplateType) {
        this.disableButtonWhenSetAsDefault = true;
        this.saveTemplateChanges(true, true);
      } else {
        this.showDialog = true;
      }
    },

    closeDialog() {
      this.showDialog = false;
    },

    reapply() {
      this.saveTemplateChanges(false, false)
        .then(() => this.reapplyTemplate())
        .finally(() => (this.showDialog = false));
    },

    async reapplyTemplate() {
      this.loadingApplySignature = true;

      let payload = {
        emails: this.currentSelected,
        template_key: this.templateData.id,
        notify_users: this.notifyUsers,
      };

      await this.setSignatureToUsers(payload)
        .then(() => {
          this.setSnackBar({
            color: "success",
            message: successMessages.set_signature_to_users,
            show: true,
          });
        })
        .catch((error) => {
          this.setSnackBar({
            color: "error",
            message: errorMessages.set_signature_to_users,
            show: true,
          });
          console.error("reapplyTemplate(): ", error);
        })
        .finally(() => {
          this.loadingApplySignature = false;
          this.showDialog = false;
        });
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    redirectToAllTemplates() {
      this.sleep(2000).then(() => {
        this.$router.push("/sign/template");
      });
    },

    async saveTemplateChanges(changeTemplateType = false, showMessages = true) {
      let html = fluxStringToTemplate(this.updatedHtml);

      this.saveChanges = false;
      this.setLoading(true);

      const url = process.env.VUE_APP_API_BASE_URL + "/conecta-sign/template";
      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      const params = {
        id_template: this.templateData.id,
        html: html,
        name: this.templateData.name,
        description: this.templateData.description,
      };

      await this.$axios
        .put(url, params, auth)
        .then(async (res) => {
          this.setUpdatedAtTemplate(res.data.template.updated_at);
          if (changeTemplateType) {
            await this.changeModelType(this.templateData.id)
              .then(({ data }) => {
                if (data.signature_type === "default") {
                  this.redirectToAllTemplates();
                }
                if (!this.showDialog) {
                  this.setSnackBar({
                    color: "success",
                    message: successMessages.template_editor,
                    show: true,
                  });
                }
              })
              .catch(({ response: { data } }) => {
                this.setSnackBar({
                  color: "error",
                  message: errorMessages[data.code],
                  show: true,
                });
              });
          } else if (showMessages) {
            this.setSnackBar({
              color: "success",
              message: successMessages.template_editor,
              show: true,
            });
          } else {
            this.setSnackBar({
              color: "success",
              message: successMessages.template_editor,
              show: true,
            });
          }
          this.setLoading(false);
        })
        .catch(({ response: { data } }) => {
          this.setLoading(false);
          this.setSnackBar({
            color: "error",
            message: errorMessages[data.code],
            show: true,
          });
        })
        .finally(() => {
          this.saveChanges = true;
          window.removeEventListener("beforeunload", this.beforeUnloadHandler);
        });
    },

    // TODO: descontinuar esta função
    async getTemplateById() {
      this.showLoading = true;

      const template_id = this.$route.params.key;

      let url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/template/${template_id}`;

      await this.$axios
        .get(url, {
          headers: {
            Authorization: this.token,
          },
        })
        .then(({ data }) => {
          this.templateData = data;
          this.setSelectedSignTemplate(data);
          this.setUpdatedAtTemplate(data.updated_at);
        })
        .catch(() => {
          this.setSnackBar({
            color: "error",
            message: errorMessages.model_not_found,
            show: true,
          });
          this.redirectToAllTemplates();
        });
    },
  },

  beforeRouteLeave(to, from, next) {
    this.next = next;
    if (this.hasChanges && !this.saveChanges) {
      this.showConfirmChanges = true;
    } else {
      this.next(true);
    }
  },

  async beforeMount() {
    await this.getTemplateById();
    await this.getUsers();

    this.currentSelected = this.impactedUsers;
    this.showLoading = false;
    if (!this.updatedHtml && this.templateData.html) {
      this.updatedHtml = this.templateData.html;
    }
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
  },
};
</script>

<style scoped>
@media screen and (max-width: 753px) {
  #sizeOfScreenErrorMessage {
    visibility: visible;
    display: block;
  }
}

@media screen and (min-width: 754px) {
  #sizeOfScreenErrorMessage {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  #sizeOfScreenForButtons {
    display: none;
  }
}

#data-table-style {
  overflow-x: hidden;
}

.help-card {
  z-index: 100;
  position: fixed;
  right: 12%;
  top: 20%;
}
.template-config input::placeholder {
  font-style: italic !important;
}

.template-config input {
  border-radius: 5px;
  border: 2px solid transparent;
}

.error-input {
  border-radius: 5px;
  border: 2px solid red !important;
}

.template-config input:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  border: 2px solid;
}
@media screen and (min-width: 1024px) {
  .template-scale {
    zoom: 0.85;
    -moz-transform: scale(0.85);
  }
}

.border-bottom {
  border-bottom: 1px solid #7272725d;
}
</style>
