<template>
  <!-- DIALOG DE INFORMAÇÕES -->
  <v-dialog
    v-model="showHideDialog"
    :key="showHideDialog"
    width="600"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip v-if="!isSelfCheckout && isConecta" bottom width="120">
        <template v-slot:activator="{ on: tooltip }">
          <v-list-item
            class="align-center"
            v-if="listItem"
            link
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-list-item-content v-html="note" />
            <v-list-item-action>
              <v-btn icon>
                <v-icon v-text="'ph-pencil-simple'" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-btn
            v-else-if="icon && !note"
            icon
            color="accent"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon v-text="'ph-fill ph-list-plus'" />
          </v-btn>
          <v-btn
            v-else-if="icon && note"
            icon
            color="success"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon v-text="'ph-fill ph-list-checks'" />
          </v-btn>
          <v-btn
            v-else
            outlined
            text
            :color="buttonColor"
            class="text-none text-body-2 font-weight-medium mx-3"
            large
            v-on="{ ...tooltip, ...dialog }"
            @click="showHideDialog = true"
          >
            <v-icon v-text="buttonIcon" size="20" left />
            {{ note ? "Editar" : "Adicionar" }} observação
          </v-btn>
        </template>
        <span>
          {{
            note
              ? "Editar observação"
              : "Escrever uma observação para este plano"
          }}
        </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar dark color="primary" flat tile>
        <v-toolbar-title
          >{{ note ? "Editar" : "Adicionar" }} observação</v-toolbar-title
        >
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark text :disabled="loading || !localNote" @click="save">
            Salvar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-progress-linear dark v-if="loading" indeterminate />

      <v-form ref="noteForm" style="width: 100%">
        <Editor
          v-model="localNote"
          :html="currentNote"
          simple-editor
          height="300"
          :placeholder="placeholder"
          @update="setNote"
        />
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import Editor from "@/components/sign/general/Editor";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "PurchaseNote",

  props: {
    note: { type: String, default: "" },
    planKey: { type: String, default: "" },
    icon: { type: Boolean, default: false },
    listItem: { type: Boolean, default: false },
    isNewPlan: { type: Boolean, default: false },
  },

  components: { Editor },

  data() {
    return {
      showHideDialog: false,
      localNote: "",
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["token", "isSelfCheckout", "isConecta"]),

    currentNote() {
      return this.note ? this.note : "";
    },

    buttonColor() {
      if (this.isNewPlan) {
        return this.note ? "success" : "accent";
      }
      return "accent";
    },

    buttonIcon() {
      if (this.isNewPlan) {
        return this.note ? "ph-fill ph-list-checks" : "ph-fill ph-list-plus";
      }
      return this.note ? "ph-fill ph-note-pencil" : "ph-fill ph-list-plus";
    },

    placeholder() {
      let placeholderText = "";
      if (this.planKey) {
        placeholderText = "Digite uma observação para o plano contratado.";
      } else {
        placeholderText =
          "Digite uma observação para a contratação deste plano.";
      }
      return `${placeholderText} Exemplo: esta empresa possui uma condição diferenciada de pagamento.`;
    },
  },

  watch: {
    note() {
      if (this.note) {
        this.localNote = this.note;
      }
    },
    planKey() {
      if (this.note) {
        this.localNote = this.note;
      }
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),
    ...mapActions(["getPlans"]),

    setNote(note) {
      this.localNote = note;
    },

    save() {
      this.$emit("note", this.localNote);
      if (!this.isNewPlan) {
        this.editNote();
      } else {
        this.showHideDialog = false;
      }
    },

    async editNote() {
      this.loading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/company-plan/${this.planKey}`;

      const payload = {
        notes: this.localNote,
      };

      const auth = {
        headers: { Authorization: this.token },
      };

      await this.$axios
        .patch(url, payload, auth)
        .then(() => {
          this.getPlans();
          this.$emit("saved", this.localNote);
          this.setSnackBar({
            message: "As observações do plano foram salvas com sucesso.",
            show: true,
            color: "success",
          });
          this.loading = false;
          this.showHideDialog = false;
        })
        .catch((err) => {
          console.error(err);
          this.setSnackBar({
            message: "Erro ao salvar observação do pedido",
            show: true,
            color: "error",
          });
          this.loading = false;
          this.showHideDialog = false;
        });
    },
  },

  beforeMount() {
    if (this.note) {
      this.localNote = this.note;
    }
  },
};
</script>
