<template>
  <v-dialog v-model="showDialog" scrollable width="1000" height="600">
    <v-card>
      <v-app-bar flat color="primary" class="white--text body-1 text-sm-h6">
        <v-app-bar-title>
          {{
            isVacationPreview
              ? $t("common.vacationModelPreview")
              : $t("common.emailSignatureModelPreview")
          }}
          {{ name ? ` - ${name}` : "" }}
        </v-app-bar-title>
        <v-spacer />
        <v-btn dark icon @click="$emit('close')">
          <v-icon v-text="'ph-x'" />
        </v-btn>
      </v-app-bar>

      <v-card-text class="px-6 mt-5 mb-4 overflow-y-hidden">
        * <span v-html="$t('common.emptyTagInfo')" />
      </v-card-text>

      <!-- Início do email preview -->

      <v-card-text class="pb-8">
        <TemplatePreview
          :loading="loading"
          :subject="subject"
          :html="html"
          :hide-bottom="hideBottom"
          :hide-top="hideTop"
          :info-to-render="infoToRender"
          :is-vacation-template="isVacationPreview"
        />
      </v-card-text>

      <v-card-actions
        v-if="applyOptions"
        :class="`pb-4 mx-4 ${isMobileScreen && 'd-block'}`"
      >
        <v-btn
          v-if="!isMobileScreen"
          color="grey darken-1"
          large
          text
          @click="$emit('close')"
          >{{ $t("action.close") }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-menu offset-y top left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              :class="`${
                isMobileScreen ? 'mb-2' : 'px-4 ml-4'
              } font-weight-bold`"
              text
              outlined
              large
              :block="isMobileScreen"
              v-bind="attrs"
              v-on="on"
            >
              Editar
            </v-btn>
          </template>
          <v-list class="px-2">
            <v-list-item class="px-4" link v-if="signature_type === 'custom'">
              <v-list-item-title @click="$emit('redirectEditTemplate')">
                Editar este modelo
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="px-4" link>
              <v-list-item-title @click="$emit('redirectToTemplateCopy')">
                Duplicar modelo
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y top left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              :class="`${
                isMobileScreen ? 'my-4' : 'px-4 ml-4'
              } font-weight-bold`"
              dark
              large
              :block="isMobileScreen"
              v-bind="attrs"
              v-on="on"
            >
              Aplicar
              <v-divider class="ml-3 mr-2" vertical v-if="!isMobileScreen" />
              <v-icon v-text="'ph-fill ph-caret-down'" right class="my-auto" />
            </v-btn>
          </template>
          <v-list class="px-2">
            <v-list-item class="px-4" link>
              <v-list-item-title @click="$emit('goUserPage')">
                Aplicar em um usuário
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="px-4" link>
              <v-list-item-title @click="$emit('goUosPage')">
                Aplicar em massa
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>

      <v-card-actions v-else class="pt-0 pb-4 mr-2">
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-1"
          class="px-4"
          large
          text
          @click="$emit('close')"
          >{{ $t("action.close") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TemplatePreview from "@/components/sign/general/TemplatePreview.vue";

export default {
  name: "DialogPreview",
  components: { TemplatePreview },
  props: {
    html: { type: String, required: true },
    name: { type: String, default: "" },
    signature_type: { type: String, default: "" }, //"default" "custom"
    show: { type: Boolean, required: true },
    applyOptions: { type: Boolean, default: false },
    subject: { type: String, default: "" },
    hideBottom: { type: Boolean, default: false },
    hideTop: { type: Boolean, default: false },
    infoToRender: { type: [Object, Boolean], default: false },
    isVacationPreview: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    isMobileScreen() {
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
